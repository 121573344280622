/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a{
  text-decoration: none !important;
  color: white !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('assets/images/bg_image.webp');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
  width: 173px;
  height: 84px;
}

.w-80{
  width: 80%;
}

.w-90{
  width: 90%;
}

.w-95{
  width: 95%;
}


.w-98{
  width: 95%;
}

.ml-1{
  margin-left: 1%;
}

.mr-1{
  margin-right: 1%;
}

.border-customGray{
  height: 20px;
  border: 1px solid #cecece;
}

.color-customGray{
  color: #9e9e9e !important;
}

input{
  background-color: white !important;
}

input::placeholder{
  color: #9e9e9e !important;
}

.form-control:focus{
  border: 1px solid #cecece !important;
}

.color-yellow{
  color: #FBC02D;
}

.bg-yellow{
  background-color: #FBC02D;
}

.bg-grad{
  background-image: linear-gradient(black ,10%, white);
}

.fw-300{
  font-weight: 300 !important;
}

.bg-brown{
  background-color: #424242;
}

.icon-img{
  width: 45px;
  height: 45px;
}

.ReactModal__Content{
  position: absolute;
  border: none !important;
  background: none !important;
  top: 30% !important;
  left: 40% !important;
}

.input-wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  border: 1px solid #cecece;
  padding: 0.7rem;
}

.input-wrapper input{
  border: none;
  height: 100%;
}

.input-wrapper input:focus{
  outline: none;
}

button[disabled=disabled], button:disabled {
  background: transparent !important;
}

button{
  overflow: hidden;
  white-space: nowrap;
}

.mb-custom-4{
  margin-bottom: 1.5rem !important;
}

@media (max-width: 770px) {
  .fs-13{
    font-size: 13px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }
  .mb-custom-4{
    margin-bottom: 0.4rem !important;
  }
  /* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 10.5px 14px !important;
  } */

  .btn{
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
}


.inputFname{
  border: none !important;
  outline: none !important;
}

p{
  margin-bottom:  0.5rem !important;
}

.pt-50{
  padding-top: 50%;
}

option{
  width: 100vh;
  height: 100vh;
  border-bottom: 1px solid gray;
}

.bb{
  border-bottom: 1px solid rgb(192, 190, 190);
}

.go-btn{
  padding-right: 11px !important;
  border: 1px solid #827a7a;
}